import './App.css';
import Example from './Example/Example';
import NewExample from './NewExample/NewExample';
import data from './data.json';
import { useState } from 'react';

function App() {

    const [examples, setExamples] = useState(data.examples);

    function addNew (newExample) {
        setExamples([newExample, ...examples]);
        // Scroll into view
        document.querySelector('.ExamplesContainer').scrollIntoView({behavior: 'smooth'});
    }

    return (
        <div className="App">
            <div className="NewExample">
                <NewExample addNew={addNew}/>
            </div>
            <div className="ExamplesContainer">
                {
                    examples.map((item, index) => {
                        return (
                            <Example
                                key={index}
                                example={item}
                            />
                        )
                    })
                }
            </div>
        </div>
    );
}

export default App;
