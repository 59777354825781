import './NewExample.css';

export default function NewExample({ addNew }) {
    return (
        <div className="new-example">
            <h2>Nuevo ejemplo</h2>
            <label htmlFor="name">Name</label>
            <input type="text" id="name" />
            <label htmlFor="Ancho">Ancho x Alto</label>
            <div className='ancho-alto'>
                <input type="number" id="Ancho" />
                <p htmlFor="Alto"><span>x</span></p>
                <input type="number" id="Alto" />
            </div>
            <label htmlFor="Precio">Precio</label>
            <input type="number" id="Precio" />
            <button
                onClick={
                    (e) => {
                        e.preventDefault();
                        const name = document.getElementById('name').value;
                        const ancho = document.getElementById('Ancho').value;
                        const alto = document.getElementById('Alto').value;
                        const precio = document.getElementById('Precio').value;
                        addNew({ name, ancho, alto, precio });
                    }
                }
            >
                Add New
            </button>
        </div>
    );
}