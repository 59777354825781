import './Example.css'
import calculateLimitedEditions from '../calc.functions'
import { useEffect, useState } from 'react'

export default function Example({ example }) {

    const [edLimState, setEdLimState] = useState(calculateLimitedEditions(example.alto, example.ancho, example.precio))

    useEffect(() => {
        setEdLimState(calculateLimitedEditions(example.alto, example.ancho, example.precio))
        console.log(example)
    }, [example])

    return (
        <div className="example">
            <h2>{example.name}</h2>
            <div className="example__original">
                <h4>Original data</h4>
                <div className="example__original__data">
                    <div>
                        <p>Tamaño</p>
                        <p>{example.ancho} x {example.alto}</p>
                    </div>
                    <div>
                        <p>Proporción</p>
                        <p>
                            {
                                // Round to 2 decimals
                                Math.round((Math.max(example.ancho, example.alto) / Math.min(example.ancho, example.alto)) * 100) / 100
                            }
                        </p>
                    </div>
                    <div>
                        <p>Precio</p>
                        <p>{example.precio} €</p>
                    </div>
                </div>
            </div>
            <div className="example__editions">
                {
                    Array.isArray(edLimState) ?
                        edLimState.map((edition, index) => {
                            return (
                                <div key={index} className='example__limited_edition'>
                                    <h4>{edition.name}</h4>
                                    <div className="example__original__data">
                                        <div>
                                            <p>Tamaño</p>
                                            <p>{Math.round(edition.ancho * 100) / 100} x {Math.round(edition.alto * 100) / 100}</p>
                                        </div>
                                        <div>
                                            <p>Unidades</p>
                                            <p>{edition.unidades}</p>
                                        </div>
                                        <div>
                                            <p>Precio</p>
                                            <p>{edition.precio} €</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <p>{edLimState.error}</p>
                }
            </div>
        </div>
    )
}